<template>
  <BasicModal
    :title="title"
    width="500px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <BasicForm
        ref="formRef"
        :labelCol="{ span: 5 }"
        :formItemsMap="formItemsMap"
        v-model:formData="formData"
        @change="changeForm"
      ></BasicForm>
    </a-spin>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  computed,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { apiRole, apiUser } from "@/api/authority";
import { useUserStore } from "@/store/modules/user";
import { message } from "ant-design-vue";
import {
  validateEmail,
  validatePhone,
} from "@/components/basic/form/validator";

const userStore = useUserStore();
const props = defineProps({
  userId: {
    type: Number,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

const title = computed(() => `${props.userId ? "修改" : "添加"}用户`);

onMounted(async () => {
  await initTenantList();
  await initFormData();
  initRoleList();
  initProjectTree();
});

const propMap = {
  email: {
    label: "邮箱",
    index: 3,
  },
  mobile: {
    label: "电话",
    index: 4,
  },
};
const asyncValidate = async (_rule, value) => {
  if (!value) {
    return Promise.resolve();
  }
  const { field } = _rule;
  const { label } = propMap[field];
  const params = {};
  params[field] = value;
  if (props.userId) {
    params.id = props.userId;
  }
  try {
    const { result } = await apiUser.validation(params);
    if (result[field] !== null) {
      if (result[field]) {
        return Promise.resolve();
      } else {
        return Promise.reject(`该${label}已被使用`);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
const validateNodeIds = async (_rule, value) => {
  if (value?.length) {
    return Promise.resolve();
  } else {
    return Promise.reject("请选择");
  }
};
const formItemsMap = reactive({
  tenantId: {
    label: "所属租户",
    prop: "tenantId",
    type: "select",
    options: [],
    fieldNames: {
      label: "tenantName",
      value: "tenantId",
    },
    required: true,
    requiredMessage: "请选择",
    disabled: false,
    loading: false,
  },
  username: {
    label: "用户名",
    prop: "username",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  nickname: {
    label: "姓名",
    prop: "nickname",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  email: {
    label: "邮箱",
    prop: "email",
    type: "input",
    required: true,
    requiredMessage: "请输入",
    rules: [
      { validator: validateEmail, trigger: "blur" },
      { validator: asyncValidate, trigger: "blur" },
    ],
  },
  mobile: {
    label: "电话",
    prop: "mobile",
    type: "input",
    required: true,
    requiredMessage: "请输入",
    rules: [
      { validator: validatePhone, trigger: "blur" },
      { validator: asyncValidate, trigger: "blur" },
    ],
  },
  roleIds: {
    label: "用户角色",
    prop: "roleIds",
    type: "select",
    mode: "multiple",
    options: [],
    fieldNames: {
      label: "name",
      value: "id",
    },
    required: true,
    requiredMessage: "请选择",
    loading: false,
  },
  nodeType: {
    label: "项目权限",
    prop: "nodeType",
    type: "radio",
    options: [
      {
        label: "按区域授权",
        value: 1,
      },
      {
        label: "按站点授权",
        value: 2,
      },
    ],
    required: true,
    requiredMessage: "请选择",
  },
  nodeIds: {
    label: " ",
    prop: "nodeIds",
    type: "tree",
    fieldNames: { title: "name", key: "id" },
    colon: false,
    options: [],
    rules: [{ validator: validateNodeIds }],
    loading: false,
    checkStrictly: true,
  },
});
const changeForm = async ({ prop }) => {
  if (prop === "tenantId") {
    formData.roleIds = undefined;
    initRoleList();
    initProjectTree();
  } else if (prop === "nodeType") {
    initProjectTree();
  }
};
const initTenantList = async () => {
  formItemsMap.tenantId.disabled = !!props.userId || !userStore.isPlatformAdmin;
  formItemsMap.tenantId.loading = true;
  try {
    const { result } = await apiRole.tenants();
    formItemsMap.tenantId.options = result;
  } catch (error) {
    console.log(error);
  }
  formItemsMap.tenantId.loading = false;
};
const initRoleList = async () => {
  formItemsMap.roleIds.loading = true;
  try {
    const { result } = await apiRole.listByTenant(formData.tenantId);
    result.forEach((item) => {
      item.disabled = item.type === 1 || item.type === 2;
    });
    formItemsMap.roleIds.options = result;
  } catch (error) {
    console.log(error);
  }
  formItemsMap.roleIds.loading = false;
};
const initProjectTree = async () => {
  const { tenantId, nodeType } = formData;
  const _params = {
    tenantId,
    permissionType: nodeType,
  };
  formItemsMap.nodeIds.loading = true;
  try {
    const { result } = await apiUser.projectTree(_params);
    formItemsMap.nodeIds.options = result;
  } catch (error) {
    console.log(error);
  }
  formItemsMap.nodeIds.loading = false;
};
const formData = reactive({
  tenantId: undefined,
  username: "",
  nickname: "",
  email: "",
  mobile: "",
  roleIds: [],
  nodeType: 1,
  nodeIds: [],
});
const loading = ref(false);
const initFormData = async () => {
  if (!props.userId) {
    // 新增
    if (userStore.isPlatformAdmin) {
      // 平台管理员
      const { options } = formItemsMap.tenantId;
      options.length && (formData.tenantId = options[0].tenantId);
    } else {
      // 租户管理员
      formData.tenantId = userStore.userInfo?.tenantId;
    }
  } else {
    // 修改
    loading.value = true;
    try {
      const { result } = await apiUser.detail(props.userId);
      for (const key in formData) {
        formData[key] = result[key];
      }
    } catch (error) {
      console.log(error);
    }
    loading.value = false;
  }
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const { userId } = props;
    const params = {
      ...formData,
    };
    userId && (params.id = userId);
    confirmLoading.value = true;
    await apiUser[userId ? "edit" : "add"](params);
    message.success(`${userId ? "修改" : "添加"}成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
