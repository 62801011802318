<template>
  <div class="user-manage">
    <div class="user-manage-header">
      <CategorySearch :source="filterSource" @change="search" />
      <a-button type="primary" @click="showModal(null)">添加</a-button>
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotStatus="{ row }">
        {{ userStatusMap[row.status] }}
      </template>
      <template #slotProjectAuth="{ row }">
        <a-popover
          v-if="row.userManageNodeResponse"
          :overlayStyle="{ width: '300px' }"
          :title="`${row.username}-项目权限`"
          placement="leftBottom"
          trigger="click"
          destroyTooltipOnHide
          overlayClassName="user-manage-popover"
        >
          <template #content>
            <a-tree
              defaultExpandAll
              :fieldNames="{
                title: 'nodeName',
                key: 'nodeId',
              }"
              :tree-data="[row.userManageNodeResponse]"
            >
            </a-tree>
          </template>
          <a-button type="link" size="small" class="user-manage-table-button">
            查看
          </a-button>
        </a-popover>
        <span v-else>/</span>
      </template>
    </BasicTable>
  </div>
  <editUserModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :userId="currentUserId"
    @success="editSuccess"
  ></editUserModal>
  <editUserControl
    v-if="controlVisible"
    v-model:visible="controlVisible"
    :userId="currentUserId"
    @success="controlSuccess"
  ></editUserControl>
  <actionLogModal
    v-if="logModalVisible"
    v-model:visible="logModalVisible"
    :userId="currentUserId"
    :userName="currentUserName"
    @success="logSuccess"
  ></actionLogModal>
</template>
<script setup>
// defineOptions({
//   name: "UserManage",
// });
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiUser } from "@/api/authority";
import { Modal, message } from "ant-design-vue";
import editUserModal from "./components/editUserModal";
import actionLogModal from "./components/actionLogModal";
import editUserControl from "./components/editUserControl";
import { userStatusMap } from "./config";

const tableRef = ref();

onMounted(() => {
  initTableData();
});

const filterSource = [
  {
    label: "姓名",
    type: "input",
    bindKey: "nickname",
    placeholder: "请输入",
  },
  {
    label: "邮箱",
    type: "input",
    bindKey: "email",
    placeholder: "请输入",
  },
  {
    label: "电话",
    type: "input",
    bindKey: "mobile",
    placeholder: "请输入",
  },
];
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 280px)",
  },
  columns: [
    {
      title: "用户ID",
      type: "text",
      key: "id",
      width: 150,
    },
    {
      title: "所属租户",
      type: "text",
      key: "tenantName",
      width: 240,
    },
    {
      title: "用户名",
      type: "text",
      key: "username",
      width: 150,
    },
    {
      title: "姓名",
      type: "text",
      key: "nickname",
      width: 120,
    },
    {
      title: "用户状态",
      type: "customize",
      slotName: "slotStatus",
      width: 80,
    },
    {
      title: "邮箱",
      type: "text",
      key: "email",
      width: 240,
    },
    {
      title: "电话",
      type: "text",
      key: "mobile",
      width: 100,
    },
    {
      title: "用户角色",
      type: "text",
      key: "roleNames",
      width: 300,
    },
    {
      title: "项目权限",
      type: "customize",
      slotName: "slotProjectAuth",
      width: 80,
    },
    {
      title: "控制权限",
      type: "text",
      key: "controlFlag",
      width: 100,
      formatter(val){
        return val?'有':'无'
      }
    },
  ],
  action: {
    title: "操作",
    width: 160,
    fixed: "right",
    items: [
    {
        type: "icon",
        icon: "ControlOutlined",
        iconTip: "权限",
        onClick: (row) => {
          showControlModal(row);
        },
      },
      {
        type: "icon",
        icon: "FileOutlined",
        iconTip: "日志",
        onClick: (row) => {
          showLogModal(row);
        },
      },
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "ReloadOutlined",
        iconTip: "重置密码",
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否重置该用户密码？",
            okType: "warning",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiUser
                  .resetPassword(row.id)
                  .then(() => {
                    message.success("重置成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        iconTip: "删除",
        visibleFn: (row) => {
          const { roleTypes } = row;
          if (roleTypes) {
            return !(roleTypes.includes(1) || roleTypes.includes(2));
          } else {
            return true;
          }
        },
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否删除该条数据？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiUser
                  .delete(row.id)
                  .then(() => {
                    message.success("删除成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    // message.error("删除失败");
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    const res = await apiUser.page(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const modalVisible = ref(false);
const currentUserId = ref(null);
const showModal = (row) => {
  const { id } = row || {};
  currentUserId.value = id || null;
  modalVisible.value = true;
};
const editSuccess = () => {
  initTableData();
};


const controlVisible = ref(false);
const showControlModal = (row) => {
  const { id } = row || {};
  currentUserId.value = id || null;
  controlVisible.value = true;
};
const controlSuccess = ()=>{
  initTableData();
}

const logModalVisible = ref(false);
const currentUserName = ref('');
const showLogModal = (row) => {
  const { id, username } = row || {};
  currentUserId.value = id || null;
  currentUserName.value = username || null;
  logModalVisible.value = true;
};
const logSuccess = ()=>{}
</script>
<style lang="less" scoped>
.user-manage {
  height: 100%;
  padding: 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>
<style lang="less">
.user-manage-popover {
  .ant-popover-title {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
