<template>
  <BasicModal
    :title="title"
    width="800px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :footer="null"
    :bodyStyle="bodyStyle"
    closable
    @cancel="cancel"
  >
    <div>
      <a-range-picker valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" class="datepicker"
          :disabled-date="disabledDate" 
          v-model:value="searchTimes" @change="onChange" />
      <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      </BasicTable>
    </div>
  </BasicModal>
</template>

<script setup>
import { BasicTable } from "@/components/basic/table";
import { computed, defineProps,defineEmits, ref, reactive, onMounted } from "vue";
import { apiUser } from "@/api/authority";
const props = defineProps({
  userId: {
    type: Number,
    default: null,
  },
  userName: {
    type: String,
    default: "",
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const bodyStyle = {
  height: '580px'
}
const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "390px",
  },
  columns: [
    {
      title: "日志时间",
      type: "text",
      key: "logTime",
      width: 200,
    },
    {
      title: "日志内容",
      type: "text",
      key: "logContent",
    },
  ],
});

const title = computed(() => {
  return `${props.userName}用户日志`;
});
const startTime = ref(undefined)
const endTime = ref(undefined)
const onChange = (time)=>{
  if(time){
    startTime.value = time[0]
    endTime.value = time[1]
  }else{
    startTime.value = undefined
    endTime.value = undefined
  }
  tableRef.value.paginationProps.current = 1;
  initTableData()
}
const initTableData = async()=>{
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    userid: props.userId+'',
    timeBegin: startTime.value,
    timeEnd: endTime.value
  };
  console.log(3,params)
  tableConfig.loading = true;
  try {
    const res = await apiUser.actionLog(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
}
const pageChange = (index)=>{
  tableRef.value.paginationProps.current = index;
  initTableData()
}
const emit = defineEmits(["update:visible"]);
const cancel = () => {
  emit("update:visible", false);
};
onMounted(async () => {
  console.log(33, props);
  initTableData()
});
</script>

<style lang="less" scoped>
.datepicker{
  margin-bottom: 16px;
}
</style>
