<template>
  <BasicModal
    title="控制授权"
    width="500px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <BasicForm
        ref="formRef"
        :labelCol="{ span: 7 }"
        :formItemsMap="formItemsMap"
        v-model:formData="formData"
        @change="changeForm"
      ></BasicForm>
    </a-spin>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive, onMounted } from "vue";
import { apiUser } from "@/api/authority";
import { message } from "ant-design-vue";
const props = defineProps({
  userId: {
    type: Number,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

onMounted(async () => {
  await initFormData();
});

const formItemsMap = reactive({
  codeControlFlag: {
    label: "指令控制启用",
    prop: "controlFlag",
    type: "switch",
    checkedValue: true,
    unCheckedValue: false,
  },
  passwordControlFlag: {
    label: "操作密码启用",
    prop: "passwordFlag",
    type: "switch",
    checkedValue: true,
    disabled: false,
    unCheckedValue: false,
  },
  passwordActionType: {
    label: "操作密码类型",
    prop: "passwordType",
    type: "select",
    hide: true,
    options: [
      {
        label: "同用户登录密码",
        value: "01",
      },
      {
        label: "自定义操作密码",
        value: "02",
      },
    ],
    disabled: false,
    required: true,
    requiredMessage: "请选择",
  },
  password: {
    label: "自定义操作密码",
    prop: "customPassword",
    type: "input",
    required: true,
    hide: true,
    requiredMessage: "请输入",
  },
});
const changeForm = async ({ prop }) => {
  if (prop === "controlFlag") {
    console.log(1)
    if (formData[prop] == false) {
      formData['passwordFlag'] = false;
      formItemsMap.passwordActionType.hide = true;
      formItemsMap.passwordControlFlag.disabled = true;
      formItemsMap.password.hide = true;
    } else{
      formItemsMap.passwordControlFlag.disabled = false;
    }
  }
  if (prop === "passwordFlag") {
    if (formData[prop] == true) {
      formItemsMap.passwordActionType.hide = false;
      if(formData['passwordType'] == '02'){
        formItemsMap.password.hide = false;
      }
      
    } else {
      formItemsMap.passwordActionType.hide = true;
      formItemsMap.password.hide = true;
    }
  }
  if (prop === "passwordType") {
    if (formData[prop] == "01") {
      formItemsMap.password.hide = true;
    } else {
      formItemsMap.password.hide = false;
    }
  }
};

const formData = reactive({
  controlFlag: false,
  passwordFlag: false,
  passwordType: "",
  customPassword: "",
});
const loading = ref(false);
const initFormData = async () => {
  const { result } = await apiUser.controlDetail(props.userId);
  for (const key in formData) {
    formData[key] = result[key];
  }
  formItemsMap.passwordActionType.hide = !result.passwordFlag;
  formItemsMap.password.hide = !(result.passwordType == '02' && result.passwordFlag);
  formItemsMap.passwordControlFlag.disabled = !result.controlFlag
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const { userId } = props;
    const params = {
      ...formData,
    };
    userId && (params.userId = userId);
    confirmLoading.value = true;
    await apiUser.controlSet(params);
    message.success(`操作成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
